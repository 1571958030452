@import "../../../../../src/styles/colors.scss";
@import "../../../../../src/styles/typographies.scss";
@import "../../../../../src/styles/custom.scss";
.o-career-containter-profile {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: flex-start;
  @media (max-width: 767px) {
    gap: 12px;
  }
  //margin-bottom: 80px;
  .o-career-container__top {
    gap: 20px;
    align-items: flex-start;
    @media (max-width: 767px) {
      gap: 12px;
    }
  }
}
.o-career-container {
  &__top {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-bottom: 8px;
    @media (max-width: 767px) {
      margin-bottom: 0px;
    }
    &__right {
      display: flex;
      flex-direction: column;
      gap: 7px;
      &__heading {
        color: $color-gulf-blue;

        @include TTCommonsMedium;
        font-size: 15px;
        line-height: 17px;
        display: flex;
        align-items: center;
        gap: 6px;
        span {
          cursor: pointer;
        }
      }
      label {
        color: var(--field-label-color);
        font-size: 15px;
        padding: 5px 0 0 0;
        margin: 0 0 6px 8px;
        line-height: 14px;
        display: block;
        font-family: "TTCommons-Regular";
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: left;
        width: 100%;
      }
    }
  }
  &__profile {
    display: flex;
    flex-direction: column;
    gap: 6px;
    &-resume-name {
      a {
        text-decoration: none;
        span {
          display: block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 185px;
          color: var(--link-color);
          font-family: "TTCommons-Medium";
          font-size: 18px;
          &:hover {
            color: var(--secondary-color);
          }
        }
        &:focus {
          text-decoration: underline;
        }
      }
    }
    &__heading {
      font-family: "TTCommons-Regular";
      padding: 5px 0 0 0;
      margin: 0 0 0px 0;
      line-height: 12px;
      display: block;
      color: var(--field-label-color);
      font-size: 15px;
    }

    &__upload {
      position: relative;
      display: flex;
      align-items: center;
      // gap: 15px;
      &__icon {
        img {
          width: 60px;
          height: 60px;
        }
      }
      &__inside {
        // background: $color-klien-blue;
        // padding: 10px 15px;
        // border-radius: 45px;
        // min-width: 102px;
        // // max-width: 150px;
        // height: 37px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // gap: 7px;
        // cursor: pointer;

        width: 175px !important;
        &__img {
          height: 14px;
          width: 14px;
        }
        &__button {
          margin: 0;
          color: $color-white;
          @include TTCommonsRegular;
          font-weight: 500;
          font-size: 18px;
          line-height: 17px;
        }
      }
    }
  }
  &__top-footer {
    display: flex;
    flex-direction: column;
    &__inside {
      display: flex;
      flex-direction: column;
      gap: 6px;
      &__heading {
        font-family: "TTCommons-Regular";
        padding: 5px 0 0 0;
        margin: 0 0 0px 0;
        line-height: 12px;
        display: block;
        color: var(--field-label-color);
        font-size: 15px;
      }
      textarea {
        height: 100px !important;
      }
    }
  }
}

@media (max-width: 78em) {
  .o-career-containter-profile {
    grid-template-columns: 1fr;
    .o-career-container__top-footer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
  }
}

@media (max-width: 48em) {
  .o-career-containter-profile {
    .o-career-container__top {
      display: grid;
      grid-template-columns: 1fr;
    }
    .o-career-container__top-footer {
      grid-template-columns: 1fr;
    }
  }
  .o-career-container {
    &__top {
      grid-template-columns: 1fr;
    }
    &__top-footer {
      margin-top: 15px;
    }
  }
}
