@import "../../../../../src/styles/colors.scss";
@import "../../../../../src/styles/typographies.scss";
@import "../../../../../src/styles/custom.scss";
.profile-education__collapse {
  .profile-education__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: flex-start;
    .profile-education__grid__row {
      gap: 20px;
      align-items: flex-start;
      @media (max-width: 767px) {
        gap: 12px;
      }
    }
  }
}
.profile-education {
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 30px;
    @media (max-width: 767px) {
      gap: 12px;
    }
    &__row {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      align-items: flex-start;
      @media (max-width: 767px) {
        gap: 12px;
      }
      &__drop-down-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 8px;
        @media (max-width: 767px) {
          gap: 12px;
        }
      }
      &__inputs {
        label {
          color: var(--field-label-color);
          font-size: 15px;
          padding: 5px 0 0 0;
          margin: 0 0 6px 8px;
          line-height: 14px;
          display: block;
          font-family: "TTCommons-Regular";
          text-align: left;
          display: flex;
          align-items: center;
          justify-content: left;
          width: 100%;
        }
      }
    }
  }
  &__more-education {
    margin-top: 45px;

    &__hr {
      margin: 0;
      color: $color-grey-6;
      margin-bottom: 30px;
    }
    &__addition {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      span {
        color: $color-orange;
        font-size: 18px;
        line-height: 19px;
        @include TTCommonsRegular;
      }
      &__underline {
        border-bottom: 1px solid $color-orange;
      }
    }
    &__remove {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin-top: 30px;
      span {
        color: $color-orange;
        font-size: 18px;
        line-height: 19px;
        @include TTCommonsRegular;
      }
      &__border {
        border: 1px solid $color-orange;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 25px !important;
      }
    }
  }
}
@media (max-width: 78em) {
  .profile-education__collapse {
    .profile-education__grid {
      grid-template-columns: 1fr 1fr;
    }
  }
}
@media (max-width: 48em) {
  .profile-education__collapse {
    .profile-education__grid {
      grid-template-columns: 1fr;
      .profile-education__grid__row {
        grid-template-columns: 1fr;
      }
    }
  }
  .profile-education {
    &__grid {
      grid-template-columns: 1fr;
      &__row {
        grid-template-columns: 1fr;
      }
    }
  }
}
