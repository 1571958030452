@import "../../../../../src/styles/colors.scss";
@import "../../../../../src/styles/typographies.scss";
@import "../../../../../src/styles/custom.scss";
.image-upload-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
  &__heading {
    font-family: "TTCommons-Regular";
    padding: 5px 0 0 0;
    margin: 0 0 0 8px;
    line-height: 12px;
    display: block;
    color: var(--field-label-color);
    font-size: 15px;
  }
  &__images {
    display: flex;
    align-items: center;
    &__image {
      height: 60px;
      width: 60px;
      border-radius: 50%;
    }
    &__loader {
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    img {
      border: 2px solid var(--light-gray-color);
      border-radius: 100%;
    }
    &__upload {
      background-color: #0030b5;
      padding: 10px 15px;
      border-radius: 45px;
      //width: 105px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include TTCommonsRegular;
      gap: 7px;
      cursor: pointer;
      &__img {
        height: 14px;
        width: 14px;
      }
      &__button {
        margin: 0;
        color: $color-white;
        @include TTCommonsRegular;
        font-weight: 500;
        font-size: 18px;
        line-height: 17px;
      }
    }
  }
}
