@import "../../../styles/colors.scss";
@import "../../../styles/breakpoints.scss";
@import "../../../styles/typographies.scss";
.a-input {
  width: 100%;
}
.a-input-main-container {
  position: relative;
}
.edit-option-container {
  padding-right: 5px;
  .edit-option {
    color: var(--link-color);
    font-family: "TTCommons-Medium";
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 12px;
    border-bottom: 1px solid var(--link-color);
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
    &:focus {
      color: var(--primary-color);
    }
  }
}
.error-container {
  //position: absolute;
  // padding-left: 10px;
  text-align: center;
  padding-top: 4px;
  color: var(--secondary-color);
}
.input-clear-icon {
  position: absolute;
  // right: 42px;
  // bottom: 9px;
  top: -2px;
  right: 4px;
  svg {
    width: 18px;
  }
}
.a-input--disabled {
  //disable
  cursor: not-allowed;
}
.a-input--readonly {
  border-color: var(--gray-color) !important;
}
.a-input__startchat--search {
  border-radius: 10px;
  box-sizing: border-box;
  border: 2px solid var(--gray-outline-color);
  border-radius: 45px;
  background-color: #ffffff;
  outline: none;
  padding: 3px 32px 0 16px;
  color: $color-blue;
  letter-spacing: 0;
  font-size: 18px;
  font-weight: 500;
  font-family: "TTCommons-Regular";
  min-height: 50px;
  width: 100%;

  @include xss() {
    width: 100%;
    min-width: auto;
  }

  &::-webkit-input-placeholder {
    color: var(--gray-text-color) !important;
    //opacity: 1;
  }
  &::-moz-placeholder {
    color: var(--gray-text-color) !important;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: var(--gray-text-color) !important;
    opacity: 1;
  }
  &:-moz-placeholder {
    color: var(--gray-text-color) !important;
    opacity: 1;
  }

  &:focus {
    outline: none;
    border-color: var(--gray-color) !important;
  }
}
.a-input--single-chat {
  border: 2px solid var(--gray-outline-color);
  border-radius: 45px;
  background-color: #ffffff;
  padding: 16.65px 20px 12.65px 20px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 19px; // color: var(--gray-text-color);
  color: #000c3d !important;
  font-family: "TTCommons-Regular" !important;
  box-sizing: border-box;
  height: 50px;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--field-label-color) !important;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--field-label-color) !important;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: var(--field-label-color) !important;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: var(--field-label-color) !important;
  }
  // --Focus style
  &:focus {
    border-color: var(--gray-color) !important;
  }
  &:hover {
    border-color: #bfced8;
  }
}
.a-input--single-chat__label {
  color: var(--field-label-color);
  font-size: 15px;
  padding: 5px 0 0 0;
  margin: 0 0 6px 8px;
  line-height: 12px;
  display: block;
  font-family: "TTCommons-Regular";
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
}

.a-input--profile__label {
  color: var(--field-label-color);
  font-size: 15px;
  padding: 5px 0 0 0;
  margin: 0 5px 7px 8px;
  line-height: 12px;
  display: block;
  font-family: "TTCommons-Regular";
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  a {
    text-decoration: none;
  }
}

.a-input-profile {
  border: 2px solid var(--gray-outline-color);
  border-radius: 45px;
  background-color: #ffffff;
  padding: 16.65px 20px 12.65px 20px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 19px; // color: var(--gray-text-color);
  color: #000c3d !important;
  font-family: "TTCommons-Regular" !important;
  box-sizing: border-box;
  min-height: 50px;
  margin: 0;
  width: 100%;
  height: 50px;
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--field-label-color) !important;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--field-label-color) !important;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: var(--field-label-color) !important;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: var(--field-label-color) !important;
  }
  // --Focus style
  &:focus {
    border-color: var(--gray-color) !important;
  }
  &:hover {
    border-color: #bfced8;
  }
}
.a-input-profile__completed {
  border: 2px solid var(--gray-color);
  border-radius: 45px;
  background-color: #ffffff;
  padding: 16.65px 20px 12.65px 20px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 19px; // color: var(--gray-text-color);
  color: #000c3d !important;
  font-family: "TTCommons-Regular" !important;
  box-sizing: border-box;
  min-height: 50px;
  margin: 0;
  width: 100%;
  height: 50px;
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--field-label-color) !important;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--field-label-color) !important;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: var(--field-label-color) !important;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: var(--field-label-color) !important;
  }
  // --Focus style
  &:focus {
    border-color: var(--gray-color) !important;
  }
  &:hover {
    border-color: #bfced8;
  }
}
.a-input-profile__password {
  border: 2px solid var(--gray-outline-color);
  border-radius: 45px;
  background-color: #ffffff;
  padding: 16.65px 20px 12.65px 20px;
  font-size: 34px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 40px; // color: var(--gray-text-color);
  color: #000c3d !important;
  font-family: "TTCommons-Regular" !important;
  box-sizing: border-box;
  min-height: 50px;
  margin: 0;
  width: 100%;
  height: 50px;
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--field-label-color) !important;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--field-label-color) !important;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: var(--field-label-color) !important;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: var(--field-label-color) !important;
  }
  // --Focus style
  &:focus {
    border-color: var(--gray-color) !important;
  }
  &:hover {
    border-color: #bfced8;
  }
}
.a-input-search {
  border: none;
  font-size: 18px;
  width: 85%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "TTCommons-Regular";
  font-weight: 500;
  color: var(--primary-dark-color) !important;
  &::placeholder {
    text-overflow: ellipsis;
  }
  &::-webkit-input-placeholder {
    color: #607790 !important;
    opacity: 1;
  }
  &::-moz-placeholder {
    color: #607790 !important;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: #607790 !important;
    opacity: 1;
  }
  &:-moz-placeholder {
    color: #607790 !important;
    opacity: 1;
  }
}
